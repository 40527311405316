* {
  margin: 0;
  padding: 0;
}

html,body {
  font-size: 10px;
}

body {
  background-color: white;
  font-family: "Helvetica Now Text", Arial, Helvetica, sans-serif;
  color: #000000;
}


a {
  font-size: 2rem;
  margin: 1rem;
  color: black;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: -1px;
  font-weight: 500;
}

header {
  padding: 2rem;
  position: sticky;
  top:0;
  background-color: white;
}

.nav-items {
  margin-right: 1rem;
}
main {
  padding: 2rem 0;
  min-height: calc(100vh - 260px);
}

footer {
  padding: 2rem;
}


.bottom-border {
  border-bottom: 1px solid #efefef;
}


.border-top {
  border-top: 1px solid #efefef;
}

/* flex */
.flex-container {
  display: flex;
  list-style: none;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.grid-container {
  display: grid;
  gap: 2rem;
  align-items: center;
}

.grid-2-col {
  grid-template-columns: 1fr 1fr;
}
.grid-3-col {
  grid-template-columns: 1fr 1fr 1fr;
}

.responsive-image img{
  width: 100%;
}

/* margins */

.m-3 {
  margin: 3rem
}

.m-t-1 {
  margin-top: 1rem
}
.m-b-2 {
  margin-bottom: 2rem
}

.m-t-3 {
  margin-top: 3rem
}

.m-t-6 {
  margin-top: 6rem
}

.m-l-3 {
  margin-left: 3rem
}

.m-r-3 {
  margin-right: 3rem
}


.m-b-1 {
  margin-bottom: 1rem
}

.m-b-3 {
  margin-bottom: 3rem
}

.p-3 {
  margin: 3rem;
  display: inline-block;
}


.p-side-3 {
  margin-left: 3rem;
  margin-right: 3rem;
  display: inline-block;
}

/* text */

.text-align-center {
  text-align: center;
}

header .active {
  text-decoration: underline;
}

.card {
  box-shadow: 0px 8px 15px rgb(75 72 72 / 10%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #efefef;
  padding: 2rem;
}

.desktop-only {
  display: block;
}

/* .chart {
  cursor: pointer
} */

@media screen and (max-width: 768px) {
  .desktop-only {
    display: none;
  }
  
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: white !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

.create-section {
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc( 100vh - 200px);
}

.image-container img{
    width: 100%;
    height: initial;
}



@media screen and (max-width: 768px) {
/* margins */
  .m-3 {
    margin: 1rem
  }

  .m-t-1 {
    margin-top: 1rem
  }
  .m-b-2 {
    margin-bottom: 2rem
  }

  .m-t-3 {
    margin-top: 1rem
  }

  .m-t-6 {
    margin-top: 3rem
  }

  .m-l-3 {
    margin-left: 3rem
  }

  .m-r-3 {
    margin-right: 3rem
  }


  .m-b-1 {
    margin-bottom: 1rem
  }

  .m-b-3 {
    margin-bottom: 3rem
  }

  .p-3 {
    margin: 3rem;
    display: inline-block;
  }


  .p-side-3 {
    margin-left: 3rem;
    margin-right: 3rem;
    display: inline-block;
  }
}
.comingsoon {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: #ffffffe3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.comingsoon h1 {
  font-size: 10vw;
  letter-spacing: .05vw;
  margin-bottom: 2rem;
}

form input {
  background-color: black;
  color: white
}
form label {
  position: fixed;
  display: block;
  left: -9999999;
}
form  input {
  font: 16px Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 16px;
  width: 300px;
  margin-bottom: 20px;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
form label input::-moz-placeholder {
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: white;
  font: 16px Helvetica, Arial, sans-serif;
}
form label input:-ms-input-placeholder {
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: white;
  font: 16px Helvetica, Arial, sans-serif;
}
form label input::placeholder {
  transition: all 0.2s ease-in-out;
  color: white;
  font: 16px Helvetica, Arial, sans-serif;
}

form button[type=submit] {
  transition: all 0.2s ease-in-out;
  font: 16px Helvetica, Arial, sans-serif;
  border: none;
  background: black;
  color: #fff;
  padding: 16px 40px;
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
}

form button[type=submit]:hover {
  background: white;
  color: black;
}